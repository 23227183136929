<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="MunicipioForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="ciudad-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="nombre"
                      rules="required|alphaSpaces"
                    >
                      <b-form-input
                        id="ciudad-name"
                        v-model="name"
                        placeholder="Nombre"
                        :state="errors.length > 0 ? false:null"
                        name="ciudad-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Estado"
                    label-for="ciudad-status"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Estado"
                    >
                      <v-select
                        v-model="status_id"
                        name="ciudad-status"
                        label="name"
                        :options="rowsStatus"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Departamento"
                    label-for="ciudad-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Departamento"
                    >
                      <v-select
                        v-model="ciudad_id"
                        name="ciudad-id"
                        label="name"
                        :options="rowsCiudad"
                        placeholder="Seleccione una opción"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, alpha, alphaSpaces,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      id: null,
      name: null,
      ciudad_id: null,
      status_id: null,
      value: [],
      options: [],
      rowsStatus: null,
      rowsCiudad: null,
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar' : 'Crear'
    },
  },
  watch: {
    infoData(val) {
      this.clear()
      if (Object.keys(val).length > 0) {
        this.name = val.name
        this.ciudad_id = val.ciudad
        this.status_id = val.status
        this.id = val.id
      }
    },
  },
  created() {
    this.getStatus()
    this.getCiudad()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clear() {
      this.name = null
      this.ciudad_id = null
      this.status_id = null
      this.id = null
    },
    getStatus() {
      this.$http.get('/v1/status/get/')
        .then(res => {
          this.rowsStatus = res.data
        })
    },
    getCiudad() {
      this.$http.get('/v1/config-ciudad/get/')
        .then(res => {
          this.rowsCiudad = res.data
        })
    },
    save() {
      this.$refs.MunicipioForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            const data = {
              name: this.name,
              ciudad_id: this.ciudad_id.id,
              status_id: this.status_id.id,
            }
            this.$http.put(`/v1/config-municipio/update/${this.id}`, data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            const data = {
              name: this.name,
              ciudad_id: this.ciudad_id.id,
              status_id: this.status_id.id,
            }
            this.$http.post('/v1/config-municipio/create', data).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
