<template>
  <div>
    <vue-country />
  </div>
</template>
<script>
import countryConfig from '@/components/country/Index.vue'

export default {
  components: {
    'vue-country': countryConfig,
  },
}
</script>
