<template>
  <div>
    <vue-city />
  </div>
</template>
<script>
import CityConfig from '@/components/city/Index.vue'

export default {
  components: {
    'vue-city': CityConfig,
  },
}
</script>
