<template>
  <div>
    <b-col cols="12">
      <good-table
        :columns="columns"
        :rows="rows"
        :select-row="true"
        title-table="Configuración de departamento"
        :is_active_create="$can('create', 'ciudad')"
        :is_active_edit="$can('update', 'ciudad')"
        :is-loading="isLoading"
        @Crear="Crear"
        @Edit_one="Edit_one"
        @edit="Edit"
        @active-register="activeRegister"
        @selection-changed="selectionChanged"
      />
    </b-col>
    <vue-form
      :modal-show="modalShow"
      :info-data="infoData"
      @create-result="getCiudad"
      @edit-result="getCiudad"
      @close="close"
    />
  </div>
</template>

<script>
import {
  BCol,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/Index.vue'
import Form from '@/components/departaments/form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BCol,
    GoodTable,
    'vue-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '230px',
        },
        {
          label: 'Nombre',
          field: 'name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Pais',
          field: 'pais.name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Estado',
          field: 'status.name',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            customFilter: false,
            placeholder: 'Selecciona un estado',
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              { value: 'Desactivado', text: 'Desactivado' },
              { value: 'Activado', text: 'Activado' },
            ],
          },
        },
        {
          label: 'Creado',
          field: 'created_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Modificado',
          field: 'updated_at',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
      ],
    }
  },
  created() {
    this.getCiudad()
  },
  methods: {
    close() {
      this.modalShow = false
      this.infoData = {}
    },
    formatFn(value) {
      if (!value) return ''
      const dateFormat = value
      return moment(dateFormat).format('YYYY-MM-DD HH:mm:ss')
    },
    getCiudad() {
      this.isLoading = true
      this.$http.get('/v1/config-ciudad/get/')
        .then(res => {
          this.isLoading = false
          this.rows = res.data
        })
    },
    activeRegister(val) {
      this.$http.put(`/v1/config-ciudad/update/active/${val.id}`, {
        active: !val.is_active,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        this.getCiudad()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    Crear() {
      this.modalShow = true
    },
    Edit(val) {
      this.infoData = val
      this.modalShow = true
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = true
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
