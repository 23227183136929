<template>
  <div>
    <vue-departaments />
  </div>
</template>
<script>
import DepartamentsConfig from '@/components/departaments/Index.vue'

export default {
  components: {
    'vue-departaments': DepartamentsConfig,
  },
}
</script>
