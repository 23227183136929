<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab
        v-if="$can('read', 'pais')"
        title="Pais"
      >
        <b-col cols="12">
          <vue-pais v-if="tabIndex == 0" />
        </b-col>
      </b-tab>
      <b-tab
        v-if="$can('read', 'ciudad')"
        title="Departamentos"
      >
        <b-col cols="12">
          <vue-departamento v-if="tabIndex == 1" />
        </b-col>
      </b-tab>
      <b-tab
        v-if="$can('read', 'municipio')"
        title="Ciudades"
      >
        <b-col cols="12">
          <vue-ciudad v-if="tabIndex == 2" />
        </b-col>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCol, BTabs, BTab,
} from 'bootstrap-vue'
import VuePais from '@/views/country/Index.vue'
import VueDepartamento from '@/views/departaments/Index.vue'
import VueCiudad from '@/views/city/Index.vue'

export default {
  components: {
    BCol,
    BTabs,
    BTab,
    'vue-pais': VuePais,
    'vue-departamento': VueDepartamento,
    'vue-ciudad': VueCiudad,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
}
</script>
